import * as _ from 'underscore';

export const getstandingOrderDays = [
  { label: "Mon", day: 1, checked: false, driver: null },
  { label: "Tue", day: 2, checked: false, driver: null },
  { label: "Wed", day: 3, checked: false, driver: null },
  { label: "Thu", day: 4, checked: false, driver: null },
  { label: "Fri", day: 5, checked: true, driver: null },
  { label: "Sat", day: 6, checked: false, driver: null },
  { label: "Sun", day: 0, checked: false, driver: null },
];


export const PAYOR = {
  medicaid: "Medicaid",
  medicare: "Medicare",
  medical: "MediCal",
  optum: "Optum",
  privatePay: "Private Pay",
  billFacility: "Bill Facility",
  tricare: "Tricare",
  champva: "CHAMPVA",
  fecaBlackLung: "FECA Black Lung",
  other: "Other"
};

export const states = [
  { value: "AK", name: "Alaska" },
  { value: "AL", name: "Alabama" },
  { value: "AR", name: "Arkansas" },
  { value: "AZ", name: "Arizona" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DC", name: "DistrictofColumbia" },
  { value: "DE", name: "Delaware" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "IA", name: "Iowa" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "MA", name: "Massachusetts" },
  { value: "MD", name: "Maryland" },
  { value: "ME", name: "Maine" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MO", name: "Missouri" },
  { value: "MS", name: "Mississippi" },
  { value: "MT", name: "Montana" },
  { value: "NC", name: "NorthCarolina" },
  { value: "ND", name: "NorthDakota" },
  { value: "NE", name: "Nebraska" },
  { value: "NH", name: "NewHampshire" },
  { value: "NJ", name: "NewJersey" },
  { value: "NM", name: "NewMexico" },
  { value: "NV", name: "Nevada" },
  { value: "NY", name: "NewYork" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "RI", name: "RhodeIsland" },
  { value: "SC", name: "SouthCarolina" },
  { value: "SD", name: "SouthDakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VA", name: "Virginia" },
  { value: "VT", name: "Vermont" },
  { value: "WA", name: "Washington" },
  { value: "WI", name: "Wisconsin" },
  { value: "WV", name: "WestVirginia" },
  { value: "WY", name: "Wyoming" },
];

export const tripReasonList = [
  "Dialysis",
  "Surgery",
  "Doctor's Appointment",
  "Gastroenterology",
  "Rheumatology",
  "Orthopedics",
  "Radiology",
  "Oncology",
  "Hematology",
  "Podiatry",
  "Wound Care",
  "Cardiology",
  "Pain Management",
  "Vascular",
  "Neurology",
  "Physical Therapy",
  "Dentistry",
  "Ophthalmology",
  "Dermatology",
  "Otolaryngology",
  "Urology",
  "Discharge from Facility",
  "Discharge from Hospital",
  "Nephrology",
  "Primary Care",
  "Endocrinology",
  "Internal Medicine",
  "Mental Health IOP Appointment",
  "Mental Health IOP Appointment-Return Home",

  "Dialysis AMB",
  "Dialysis WC",
  "Dialysis STR",
  "Doctor App AMB",
  "Doctor App WC",
  "Doctor App STR",
  "Facility to Facility AMB",
  "Facility to Facility WC",
  "Facility to Facility STR",
  "Other AMB",
  "Other WC",
  "Other STR",
  "Direct Admit AMB",
  "Direct Admit WC",
  "Direct Admit STR",
  "HOSP Discharge AMB",
  "HOSP Discharge WC",
  "HOSP Discharge STR",
  "Hospice AMB",
  "Hospice WC",
  "Hospice STR",
  
  "Other"
];

export const tripHospitalReasonList = [
"Discharge From Hospital",
"Discharge From Emergency Department",
"Inter-facility Transfer/Discharge (Psych)",
"Inter-facility Transfer/Discharge (SNF)",
"Other (See Notes)"
];

export const getstandingOrderDaysAsObject = () => {
  const object = _.object(_.pluck(getstandingOrderDays, 'day'), _.pluck(getstandingOrderDays, 'label'))
  return object;
}

export function exportToCsv(filename, rows) {
  var processRow = function (row) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null || row[j] === undefined || row[j] === "" ? 'n/a' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      };
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0)
        result = '"' + result + '"';
      if (j > 0)
        finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

  var link = document.createElement("a");
  if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }
}

export function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function titlecase(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function getRoles(roles) {
  return roles.map(role => {
    let str = '';
    if (role === 'cooperate' || role === 'ccooperate') {
      str = 'Admin'
    } else if (role === 'crcallcenter') {
      str = 'Call Center Representative';
    } else if (role === 'cdispatcher') {
      str = 'Transportation Coordinator';
    } else {
      str = 'N/A';
    }
    return str;
  });
}

export function toTitleCase(value) {
  if (typeof value !== 'string' || value === '') {
    return value;
  }
  const words = [];
  let start = 0;
  for (let i = 1; i < value.length; i++) {
    const char = value[i];
    if (char === char.toUpperCase() && char !== char.toLowerCase()) {
      words.push(value.slice(start, i));
      start = i;
    }
  }
  words.push(value.slice(start));
  const formattedWords = words.map((word, index) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return formattedWords.join(' ');
} 
import React, { useEffect } from "react";
import clsx from "clsx";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useLocation, Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import LogoutModal from "./LogoutModal";
import { getUserRoles } from "../shared/menuService";


// const UserRoles = {
//   ADMIN: 'ccooperate',
//   CALLCENTER_REP: 'crcallcenter',
//   TRANSPORT_COORDINATOR: 'cdispatcher'
// }

const UserRoles = getUserRoles();

const navigation = [
  {
    name: "Trips",
    href: "/trip/list",
    roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR],
    icon: (props) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <path
          fill="currentColor"
          d="M9 21q-1.65 0-2.825-1.175T5 17V8.825Q4.125 8.5 3.562 7.738T3 6q0-1.25.875-2.125T6 3q1.25 0 2.125.875T9 6q0 .975-.563 1.738T7 8.825V17q0 .825.588 1.413T9 19q.825 0 1.413-.588T11 17V7q0-1.65 1.175-2.825T15 3q1.65 0 2.825 1.175T19 7v8.175q.875.325 1.438 1.088T21 18q0 1.25-.875 2.125T18 21q-1.25 0-2.125-.875T15 18q0-.975.563-1.75T17 15.175V7q0-.825-.588-1.413T15 5q-.825 0-1.413.588T13 7v10q0 1.65-1.175 2.825T9 21ZM6 7q.425 0 .713-.288T7 6q0-.425-.288-.713T6 5q-.425 0-.713.288T5 6q0 .425.288.713T6 7Zm12 12q.425 0 .713-.288T19 18q0-.425-.288-.713T18 17q-.425 0-.713.288T17 18q0 .425.288.713T18 19ZM6 6Zm12 12Z"
        />
      </svg>
    ),
  },
  // {
  //   name: "History",
  //   href: "/trip/history",
  //   roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR],
  //   icon: (props) => (
  //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
  //       <path
  //         fill="currentColor"
  //         d="M11.998 2.5A9.503 9.503 0 0 0 3.378 8H5.75a.75.75 0 0 1 0 1.5H2a1 1 0 0 1-1-1V4.75a.75.75 0 0 1 1.5 0v1.697A10.997 10.997 0 0 1 11.998 1C18.074 1 23 5.925 23 12s-4.926 11-11.002 11C6.014 23 1.146 18.223 1 12.275a.75.75 0 0 1 1.5-.037a9.5 9.5 0 0 0 9.498 9.262c5.248 0 9.502-4.253 9.502-9.5s-4.254-9.5-9.502-9.5Z"
  //       />
  //       <path
  //         fill="currentColor"
  //         d="M12.5 7.25a.75.75 0 0 0-1.5 0v5.5c0 .27.144.518.378.651l3.5 2a.75.75 0 0 0 .744-1.302L12.5 12.315V7.25Z"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: "Create",
  //   href: "/trip/create",
  //   roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR],
  //   icon: (props) => (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       fill="none"
  //       viewBox="0 0 24 24"
  //       strokeWidth={1.5}
  //       stroke="currentColor"
  //       {...props}
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         d="M12 4.5v15m7.5-7.5h-15"
  //       />
  //     </svg>
  //   ),
  // },
  {
    name: "STO",
    href: "/standing-orders",
    roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR],
    icon: (props) => (
      <svg
        className="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        {...props}
      >
        <path
          fill="currentColor"
          d="M14.844 20H6.5C5.121 20 4 18.879 4 17.5S5.121 15 6.5 15h7c1.93 0 3.5-1.57 3.5-3.5S15.43 8 13.5 8H8.639a9.812 9.812 0 0 1-1.354 2H13.5c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5h-7C4.019 13 2 15.019 2 17.5S4.019 22 6.5 22h9.593a10.415 10.415 0 0 1-1.249-2zM5 2C3.346 2 2 3.346 2 5c0 3.188 3 5 3 5s3-1.813 3-5c0-1.654-1.346-3-3-3zm0 4.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 5 6.5z"
        />
        <path
          fill="currentColor"
          d="M19 14c-1.654 0-3 1.346-3 3c0 3.188 3 5 3 5s3-1.813 3-5c0-1.654-1.346-3-3-3zm0 4.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 19 18.5z"
        />
      </svg>
    ),
  },
  {
    name: "Staff",
    href: "/staff",
    roles: [UserRoles.ADMIN],
    icon: (props) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none" viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        {...props}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
      </svg>

      // <svg
      //   xmlns="http://www.w3.org/2000/svg"
      //   fill="none"
      //   viewBox="0 0 24 24"
      //   strokeWidth={1.5}
      //   stroke="currentColor"
      //   {...props}
      // >
      //   <path
      //     strokeLinecap="round"
      //     strokeLinejoin="round"
      //     d="M12 4.5v15m7.5-7.5h-15"
      //   />
      // </svg>
    ),
  },
  {
    name: "Notifications",
    href: "/notifications",
    roles: [UserRoles.ADMIN],
    icon: (props) => (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} {...props} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
      </svg>
    ),
  },
];

export default function SideNav() {
  const { pathname } = useLocation();
  const { user, sidebarOpen, setSidebarOpen, setLogoutModalOpen } = useAuth();

  const handleLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  useEffect(() => {
    // console.log('hook user', user);
  }, [user]);

  const canView = (roles) => {
    if (!user) return false;

    if (user.roles.includes('cooperate')) {
      return true;
    }

    let result;
    result = roles.filter(r => user.roles.includes(r));

    return (result && result.length) ? true : false;
  }

  return (
    <>
      <div className="hidden h-full w-28 overflow-y-auto bg-nav-dark lg:block border-r border-nav-base">
        <div className="flex w-full flex-col items-center py-4 h-full">
          <div className="flex flex-col justify-center text-center flex-shrink-0 items-center border-b pb-4 border-white/10 w-full">
            {user && user.company ? (
              <>
                <img
                  className="h-10 w-10 rounded-full object-cover"
                  src={user.company.profileImageURL}
                  alt={user.company.displayName}
                />
              </>
            ) : (
              <>
                <svg
                  className="h-10 w-10 rounded-full text-main-base"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </>
            )}
            <div className="text-xs text-nav-light mt-2 line-clamp-2">
              {user ? user.company.displayName : "loading..."}
            </div>
            {(user && user.cooperate && user.cooperate.displayName) && (
              <div className="text-[9px] text-nav-light line-clamp-2">
                {user.cooperate.displayName}
              </div>
            )}
          </div>
          <div className="mt-4 w-full flex-1 px-2 flex flex-col justify-between h-full">
            {/* Current: "bg-nav-light text-nav-light", Default: "text-nav-dark hover:bg-nav-light hover:text-nav-light" */}
            <div className="space-y-1">
              {navigation.map((item, index) => (
               user && user.type === 'facility' && item.name === 'STO' ? '': (canView(item.roles) &&
                <Link
                  key={index}
                  to={item.href}
                  className={clsx(
                    pathname === item.href
                      ? "bg-nav-light text-nav-light"
                      : "text-nav-dark hover:bg-nav-light hover:text-nav-light",
                    "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium cursor-pointer"
                  )}
                >
                  {/* Heroicon name: outline/home
  
              Current: "text-nav-light", Default: "text-nav-svg-dark group-hover:text-nav-light" */}

                  <item.icon
                    className={clsx(
                      pathname === item.href
                        ? "text-nav-svg-light"
                        : "text-nav-svg-dark group-hover:text-nav-light",
                      " h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{item.name}</span>
                </Link>)
              ))}
            </div>
            <div className="space-y-1">
              <Link
                to="/profile"
                className="group w-full py-3 px-1 rounded-md flex flex-col items-center justify-center text-center text-xs font-medium cursor-pointer text-nav-dark hover:bg-nav-light hover:text-nav-light"
              >
                {user ? (
                  <div className="h-10 w-10 rounded-full overflow-hidden ">
                    <img
                      className="h-full w-full object-cover"
                      src={user.profileImageURL}
                      alt={user.displayName}
                    />
                  </div>
                ) : (
                  <>
                    <svg
                      className="h-10 w-10 rounded-full text-main-base"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </>
                )}
                <span className="mt-2 line-clamp-2">
                  {" "}
                  {user ? <>{user.displayName}</> : <>Loading...</>}
                </span>
              </Link>
              <div
                onClick={handleLogoutModal}
                className="group w-full p-3 rounded-md flex flex-col items-center justify-center text-center text-xs font-medium cursor-pointer text-nav-dark hover:bg-nav-light hover:text-nav-light"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 text-nav-svg-dark group-hover:text-nav-light"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                  />
                </svg>

                <span className="mt-2">Logout</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-main-dark/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-nav-dark px-6 py-4">
                  <div className="flex flex-col justify-center text-center flex-shrink-0 items-center border-b pb-5 border-white/10 w-full">
                    {user && (
                      <>
                        <img
                          className="h-10 w-10 rounded-full object-cover"
                          src={user.company.profileImageURL}
                          alt={user.company.displayName}
                        />
                        <div className="text-xs text-nav-light mt-2 line-clamp-2">
                          {user.company.displayName}
                        </div>
                      </>
                    )}
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                onClick={() => setSidebarOpen(false)}
                                to={item.href}
                                className={clsx(
                                  pathname === item.href
                                    ? "bg-nav-light text-nav-light"
                                    : "text-nav-dark hover:bg-nav-light hover:text-nav-light",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer"
                                )}
                              >
                                <item.icon
                                  className={clsx(
                                    pathname === item.href
                                      ? "text-nav-svg-light"
                                      : "text-nav-svg-dark group-hover:text-nav-light",
                                    " h-6 w-6"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <LogoutModal />
    </>
  );
}

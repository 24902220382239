import React from "react";
import { useState, useEffect } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { PAYOR } from "../utils/common";
import useAuth from "../hooks/useAuth";

export default function AddonServices(props) {
    const { addons, control, register, errors } = props;
    const { user, getToken } = useAuth();

    const {
        fields: addonFields,
        append: addonAppend,
        remove: addonRemove,
    } = useFieldArray({
        control,
        name: "addonServices",
    });

    const {
        control: addonControl,
        register: addonRegister,
        handleSubmit: addonHandleSubmit,
        reset: addonReset,
        watch: addonWatch,
        setValue: addonSetValue,
        getValues: addonGetValues,
        formState: { errors: addonError },
    } = useForm({
        defaultValues: {
            quantity: 1,
            addonId: "",
        },
    });

    const onSubmitAddon = async (data) => {
        if (!addonGetValues().addonId) return;
        const addonIdToSearch = addonGetValues().addonId;
        let service = addons.addonServices.find(
            (service) => service._id === addonIdToSearch
        );
        addonAppend({
            ...addonGetValues(),
            fare: service.fare,
            total: service.fare * addonGetValues().quantity,
            title: service.title,
        });
        addonReset({
            quantity: 1,
            addonId: "",
        });
    };

    return (
        <>
    
                    <div className="sm:col-span-6">
                        {addons && (
                            <div className="bg-main-base-100 border-2 border-dashed border-main-base p-4 rounded-lg">
                                <h3 className="text-lg font-medium leading-6 text-main-dark">
                                {user && user.type === 'facility' ? "Ancillary Services" : "Addon Services"} 
                                </h3>
                                <div className="mt-6 space-y-6">
                                    {addonFields.map((item, index) => (
                                        <div
                                            key={index}
                                            className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                                        >
                                            <div className="sm:col-span-3">
                                                <label
                                                    htmlFor={`addonServices.${index}.addonId`}
                                                    className="block text-sm font-medium text-main-secondary"
                                                >
                                                    Item {index + 1}
                                                </label>
                                                <div className="mt-1">
                                                    <select
                                                        readOnly
                                                        id={`addonServices.${index}.addonId`}
                                                        {...register(
                                                            `addonServices.${index}.addonId`,
                                                            {
                                                                required: false,
                                                            }
                                                        )}
                                                        className="cursor-not-allow"
                                                    >
                                                        {addons.addonServices.map((item, index) => (
                                                            <option
                                                                disabled
                                                                key={index}
                                                                value={item._id}
                                                            >
                                                                {item.title} {user && user.type === 'facility'? '': '$' +item.fare} 
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {/* {errors.AddonItem && (
                              <p className="mt-2 text-xs text-main-danger">
                                Enter the item
                              </p>
                            )} */}
                                            </div>
                                            <div className="sm:col-span-3">
                                                <label
                                                    htmlFor="addonQuantity"
                                                    className="block text-sm font-medium text-main-secondary"
                                                >
                                                    Quantity
                                                </label>
                                                <div className="mt-1 flex gap-1 items-center">
                                                    <input
                                                        readOnly
                                                        type="number"
                                                        name="addonQuantity"
                                                        id="addonQuantity"
                                                        defaultValue={1}
                                                        {...register(
                                                            `addonServices.${index}.quantity`,
                                                            {
                                                                valueAsNumber: true,
                                                                required: false,
                                                            }
                                                        )}
                                                        className="cursor-not-allow"
                                                    />

                                                    <button
                                                        className="btn-danger py-1.5 px-2"
                                                        onClick={() => addonRemove(index)}
                                                    >
                                                        <XMarkIcon className="h-6 w-6" />
                                                    </button>
                                                </div>
                                                {errors.addonQuantity && (
                                                    <p className="mt-2 text-xs text-main-danger">
                                                        Enter the Client Name
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    {/* onSubmit={addonHandleSubmit(onSubmitAddon)} */}
                                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 border-2 border-dashed border-main-base p-4 bg-white">
                                        <div className="sm:col-span-3">
                                            <label className="block text-sm font-medium text-main-secondary">
                                                Item
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    id="AddonItem"
                                                    {...addonRegister("addonId", {
                                                        required: false,
                                                    })}
                                                >
                                                    <option value="">
                                                        -- Select {user && user.type === 'facility' ? "Ancillary" : "Addon"}  Item --
                                                    </option>
                                                    {addons.addonServices.map((item, index) => (
                                                        <option key={index} value={item._id}>
                                                 {item.title} {user && user.type === 'facility'? '': '$' +item.fare} 
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* {errors.AddonItem && (
                              <p className="mt-2 text-xs text-main-danger">
                                Enter the item
                              </p>
                            )} */}
                                        </div>
                                        <div className="sm:col-span-3">
                                            <div className="block text-sm font-medium text-main-secondary">
                                                Quantity
                                            </div>
                                            <div className="mt-1 w-full flex items-center gap-1">
                                                <input
                                                    type="number"
                                                    name="addonQuantityf"
                                                    id="addonQuantityf"
                                                    {...addonRegister("quantity", {
                                                        required: false,
                                                        valueAsNumber: true,
                                                    })}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={onSubmitAddon}
                                                    className="btn-primary py-1.5 px-2"
                                                >
                                                    <PlusIcon className="h-6 w-6" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                
        </>
    );
}
